/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import python from '../images/icons2/python.png'
import gopher from '../images/icons2/gopher.png'
import react from '../images/icons2/react.png'
import javascript from '../images/icons2/javascript.png'
import sql from '../images/icons2/sql.png'
import node from '../images/icons2/node.png'
import htmlcss from '../images/icons2/html.png'
import wordpress from '../images/icons2/wordpress.png'
import gatsby from '../images/icons2/gatsby.png'
import git from '../images/icons2/git.png'
import redux from '../images/icons2/redux.png'
import "./layout.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      {/* <div id="grid">
      <img alt="" id="python-icon" src={python} />
      <img alt="" id="gopher-icon" src={gopher} />
      <img alt="" id="react-icon" src={react} />
      <img alt="" id="javascript-icon" src={javascript} />
      <img alt="" id="sql-icon" src={sql} />
      <img alt="" id="node-icon" src={node} />
      <img alt="" id="htmlcss-icon" src={htmlcss} />
      <img alt="" id="wordpress-icon" src={wordpress} />
      <img alt="" id="gatsby-icon" src={gatsby} />
      <img alt="" id="git-icon" src={git} />
      <img alt="" id="redux-icon" src={redux} />
      <div className="column">

      </div>
      <div className="column">
        
      </div>
      <div className="column">
        
      </div>
      <div className="column">
        
      </div>
      <div className="column">
        
      </div>
      <div className="column">
        
      </div>
    </div> */}

      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div className="layout-wrapper">
      <div id="grid">
        <img alt="" id="python-icon" src={python} />
        <img alt="" id="gopher-icon" src={gopher} />
        <img alt="" id="react-icon" src={react} />
        <img alt="" id="javascript-icon" src={javascript} />
        <img alt="" id="sql-icon" src={sql} />
        <img alt="" id="node-icon" src={node} />
        <img alt="" id="htmlcss-icon" src={htmlcss} />
        <img alt="" id="wordpress-icon" src={wordpress} />
        <img alt="" id="gatsby-icon" src={gatsby} />
        <img alt="" id="git-icon" src={git} />
        <img alt="" id="redux-icon" src={redux} />
        <div className="column">

        </div>
        <div className="column">
          
        </div>
        <div className="column">
          
        </div>
        <div className="column">
          
        </div>
        <div className="column">
          
        </div>
        <div className="column">
          
        </div>
      </div>
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
